import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/Layout";
import TextCard from "../../components/textCard/TextCard";
import "./simplePage.styles.scss";
import Head from "../../components/head/Head";

const SimplePage = ({ data: { markdownRemark: { frontmatter, html } } }) => {

  const homeUrl = [
    {
      url: "/",
      link: "Startseite"
    }
  ]

  return (
    <div className="page__body">
      <Head title={frontmatter.title} robots="noindex,nofollow" />
      <Layout navbarLinks={homeUrl} sectionLink={false}>
        <main className="simple-page">
          <TextCard title={frontmatter.title} dangerouslySetInnerHTML={html} />
        </main>
      </Layout>
    </div >
  );
}

export default SimplePage;

export const query = graphql`
query($slug: String) {
  markdownRemark(frontmatter: {url: {eq: $slug}}) {
    frontmatter {
      order
      link
      title
      url
    }
    html
  }
}`

